import React from 'react'
import '../index.css'

const AboutMe = () => {
	return (
		<section className='bg-gray-800 pattern py-20'>
			<div className='max-w-5xl px-6 mx-auto text-center'>
				<h2 className='text-2xl font-semibold text-white'>About Me</h2>

				<p className='text-gray-400 mt-4'>
					I am a full-stack developer. I build web applications using React and
					NodeJS. Occassionally I make use of firebase firestore and cloud
					functions as well. For mobile applications, I use React Native and
					Firebase. <br /> <br /> When I am not coding, I am listening to Jazz
					music or watching Formula 1. I am an enthusiast with a relentless
					curiosity to learn, discover and innovate – this is one of the reasons
					I do what I do.
				</p>
			</div>
		</section>
	)
}

export default AboutMe
