import React from 'react'

const TechComponent = ({ name, component }) => {
	return (
		<div className='max-w-xs w-full'>
			<div className='flex flex-col items-center justify-center h-56 p-3 bg-white shadow-xl border-b-8 border-purple-600 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110'>
				{component}
				<h3 className='text-lg font-medium text-gray-700'>{name}</h3>
			</div>
		</div>
	)
}

export default TechComponent
