import React from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import AboutMe from './components/AboutMe'
import BlogPosts from './components/BlogPosts'
import Projects from './components/Projects'
import Footer from './components/Footer'
import Technologies from './components/Technologies'

const App = () => {
	return (
		<div className='font-sans bg-white'>
			<Header />
			<Hero />
			<AboutMe />
			<Technologies />
			<Projects />
			<Footer />
		</div>
	)
}

export default App
