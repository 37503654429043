import React from 'react'

const ProjectComponent = ({ link, image, name, description }) => {
	return (
		<div className='max-w-xs w-full'>
			<div className='flex items-center justify-center bg-white border-b-8 border-purple-600 rounded-md overflow-hidden'>
				<img className='object-cover h-auto w-auto' src={image} alt='' />
			</div>

			<a
				href={link}
				target='_blank'
				rel='noreferrer noopener'
				className='block bg-gray-700 mt-5 rounded-md overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110'
			>
				<div className='py-2 px-3 text-center text-sm'>
					<p className='text-gray-300'>{description}</p>

					<span className='block text-gray-500 mt-2'>{name}</span>
				</div>
			</a>
		</div>
	)
}

export default ProjectComponent
