import React from 'react'
import ReactLogo from './Logos/ReactLogo'
import ExpressLogo from './Logos/ExpressLogo'
import FirebaseLogo from './Logos/FirebaseLogo'
import GraphQLLogo from './Logos/GraphQLLogo'
import MongoDBLogo from './Logos/MongoDBLogo'
import NodeJSLogo from './Logos/NodeJSLogo'
import TailwindLogo from './Logos/TailwindLogo'
import TechComponent from './TechComponent'

const Technologies = () => {
	return (
		<section className='bg-white py-20'>
			<div className='max-w-5xl px-6 mx-auto text-center'>
				<h2 className='text-2xl font-semibold text-gray-800'>My Toolbox</h2>
				<div className='flex items-center justify-center mt-10'>
					<div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
						<TechComponent
							name='ReactJS'
							component={<ReactLogo width='200' />}
						/>
						<TechComponent
							name='NodeJS'
							component={<NodeJSLogo width='200' />}
						/>
						<TechComponent
							name='MongoDB'
							component={<MongoDBLogo width='200' />}
						/>
						<TechComponent
							name='Express'
							component={<ExpressLogo width='200' />}
						/>
						<TechComponent
							name='GraphQL'
							component={<GraphQLLogo width='200' />}
						/>
						<TechComponent
							name='TailwindCSS'
							component={<TailwindLogo width='200' />}
						/>
						<TechComponent
							name='Firebase'
							component={<FirebaseLogo width='200' />}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Technologies
