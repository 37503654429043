import argo from './assets/portfolio-images/argo.png'
import collabhous3 from './assets/portfolio-images/collabhous3.png'
import colors from './assets/portfolio-images/colors.png'
import easyburse from './assets/portfolio-images/easyburse.png'
import ecofood from './assets/portfolio-images/ecofood.png'
import hollard from './assets/portfolio-images/hollard.png'
import kanban from './assets/portfolio-images/kanban.png'
import purpledot from './assets/portfolio-images/purpledot.png'
import radah from './assets/portfolio-images/radah.png'
import lathitha from './assets/portfolio-images/lathitha.png'
import slack from './assets/portfolio-images/slack.png'
import up from './assets/portfolio-images/up.png'
import viscept from './assets/portfolio-images/viscept.png'

const portfolioData = [
	{
		name: 'Hollard',
		description: 'A digital concierge desk',
		image: hollard,
		link: 'https://hollard.conciergedeskonline.com',
	},
	{
		name: 'CollabHous3',
		description: 'A company focused on building SMMEs',
		image: collabhous3,
		link: 'https://collabhous3.co.za/',
	},
	{
		name: 'colors',
		description: 'A color pallete generator',
		image: colors,
		link: 'https://mihlali-colors-app.netlify.app',
	},
	{
		name: 'Easyburse',
		description: 'A talent management company',
		image: easyburse,
		link: 'http://www.ebstaging.co.za/wp/',
	},
	{
		name: 'Slack',
		description: 'A clone of the slack chat app',
		image: slack,
		link: 'https://codechats-c8048.web.app/',
	},
	{
		name: 'Ecofood',
		description: 'A premium healthy food delivery service',
		image: ecofood,
		link: 'https://ecofood.netlify.app',
	},
	{
		name: 'Viscept',
		description: 'A secure cloud Saas company',
		image: viscept,
		link: 'https://viscept.netlify.app/',
	},
	{
		name: 'Kanban',
		description: 'A drag and drop task manager',
		image: kanban,
		link: 'https://kanban-mihlali.web.app/',
	},
	{
		name: 'Argo',
		description: 'A POC for gauging social conversation',
		image: argo,
		link: 'https://argo-analytica.netlify.app/posts',
	},

	{
		name: 'PurpleDot',
		description: 'A SMME consulting business',
		image: purpledot,
		link: 'https://purpledotbs.co.za/',
	},
	{
		name: 'Radah Creatives',
		description: 'A digital consulting agency',
		image: radah,
		link: 'https://radahcreatives.com/',
	},
	{
		name: 'Lathitha',
		description: 'An NGO that aims to empower',
		image: lathitha,
		link: 'http://lathitha.org.za/',
	},
	{
		name: 'UPCS',
		description: 'A management consulting society',
		image: up,
		link: 'https://upcs.co.za/',
	},
]

export default portfolioData
